export default {
	setlastcallsform(state, lastcallsform) {
		state.lastcallsform = lastcallsform;
	},
	setshipactivitiesform(state, shipactivitiesform) {
		state.shipactivitiesform = shipactivitiesform;
	},
	setpersonslistform(state, personslistform) {
		state.personslistform = personslistform;
	},
	setpreviouscallsform(state, previouscallsform) {
		state.previouscallsform = previouscallsform;
	}
};
