var constants;

if (process.env.NODE_ENV === 'production') {
	constants = {
		NODE_ENV: 'production',
		MATOMO_URL: 'https://analytics.prodevelop-cdn.com/',
		MATOMO_ID: '9' // Dado por tech para PRO
	};
} else if (process.env.NODE_ENV === 'pre') {
	constants = {
		NODE_ENV: 'pre',
		MATOMO_URL: 'https://analytics.prodevelop-cdn.com/',
		MATOMO_ID: '8' // Dado por tech para PRE
	};
} else {
	constants = {
		NODE_ENV: 'dev',
		MATOMO_URL: 'https://analytics.prodevelop-cdn.com/',
		MATOMO_ID: '8' // Dado por tech para DEV
	};
}

export default constants;
