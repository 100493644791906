<template>
	<router-view></router-view>
</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
</style>
<script>
export default {
	name: 'App',
	created() {},
	mounted() {
		if (window.localStorage) {
			if (document.getElementById('puilogin')) {
				var login = document.getElementById('puilogin');
				var buton = document.getElementById('submitLogin');
				var divButt = document.createElement('div');
				var divButt2 = document.createElement('div');

				divButt.className = 'v-card__actions';
				divButt.appendChild(divButt2);
				divButt2.className = 'contSubmitLogin';
				divButt2.appendChild(buton);
				//fa-kit fa-icon-11
				//login.appendChild(buton);
				login.insertBefore(divButt, login.children[2]);
			} else {
				//document.getElementsByClassName('fa-user-circle')[0].classList.add('fa-icon-11');
			}
		}
	},

	updated() {
		if (document.getElementById('puilogin')) {
			var login = document.getElementById('puilogin');
			var buton = document.getElementById('submitLogin');
			var divButt = document.createElement('div');
			var divButt2 = document.createElement('div');

			divButt.className = 'v-card__actions';
			divButt.appendChild(divButt2);
			divButt2.className = 'contSubmitLogin';
			divButt2.appendChild(buton);

			login.insertBefore(divButt, login.children[2]);
		}
	}
};
</script>
