<template>
	<div>
		<pui-modal-dialog
			v-if="openDialog"
			:titleText="$t('puidatatables.prepare_import_title')"
			messageText
			:okText="$t('puidatatables.import_btn')"
			:cancelText="$t('form.cancel')"
			dialogName="importDialog"
		>
			<template v-slot:message>
				<v-container wrap>
					<v-row>
						<v-col cols="12" md="9" xl="9">
							<pui-text-field
								:label="$t('puidatatables.filename')"
								v-model="selectedFileName"
								toplabel
								required
								disabled
							></pui-text-field>
							<input type="file" v-show="false" ref="document" accept=".csv" @change="onFilePicked" />
						</v-col>
						<v-col cols="12" md="2" xl="2">
							<v-btn @click="pickFile" style="margin-top: 20px">{{ $t('puidatatables.selectfile') }}</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</pui-modal-dialog>
	</div>
</template>

<script>
export default {
	name: 'NswImportDialog',
	props: {
		openDialog: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			selectedFile: {},
			selectedFileName: ''
		};
	},
	created() {
		this.$puiEvents.$on(`pui-modalDialog-importDialog-cancel`, () => {
			this.openDialog = false;
			this.resetFilePicked();
			this.$emit('closedialog');
		});
		this.$puiEvents.$on(`pui-modalDialog-importDialog-ok`, () => {
			this.prepareImport();
			this.$emit('closedialog');
		});
	},
	unmounted() {},
	computed: {},
	methods: {
		pickFile() {
			this.$refs.document.click();
		},
		onFilePicked(e) {
			const files = e.target.files;
			if (files[0] !== undefined) {
				const file = files[0];
				if (file.name.lastIndexOf('.') <= 0 || !file.name.endsWith('.csv')) {
					this.$store.$puiNotify.error(this.$t('puidatatables.fileextension_notallowed'));
					return;
				}

				this.selectedFileName = file.name;
				const fr = new window.FileReader();
				fr.addEventListener('load', () => {
					this.selectedFile = file; // this is the file that can be sent to server...
				});
				fr.readAsDataURL(file);
			}
		},
		resetFilePicked() {
			this.selectedFileName = null;
			this.selectedFile = null;
		},
		prepareImport() {
			let reader = new FileReader();
			reader.onload = (e) => {
				// Cuando el archivo se terminó de cargar
				let lines = this.parseCSV(e.target.result);

				if (lines[lines.length - 1][0] === '') {
					lines.pop();
				}

				const headers = lines[0][0].split(';');
				lines.shift();
				lines.shift();
				const parsedData = lines.map((line) => {
					const values = line[0].split(';');
					const obj = {};
					headers.forEach((header, index) => {
						obj[header] = values[index];
					});
					return obj;
				});

				this.$emit('newData', parsedData);
				this.resetFilePicked();
			};

			// Leemos el contenido del archivo seleccionado
			reader.readAsBinaryString(this.selectedFile);
		},
		parseCSV(text) {
			let lines = text.replace(/\r/g, '').split('\n');
			return lines.map((line) => {
				// Por cada linea obtenemos los valores
				let values = line.split(',');
				return values;
			});
		},
		reverseMatrix(matrix) {
			let output = [];
			// Por cada fila
			matrix.forEach((values, row) => {
				// Vemos los valores y su posicion
				values.forEach((value, col) => {
					// Si la posición aún no fue creada
					if (output[col] === undefined) output[col] = [];
					output[col][row] = value;
				});
			});
			return output;
		}
	}
};
</script>
