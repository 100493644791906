<template>
	<v-row class="pui-form-layout">
		<v-col cols="6">
			<pui-select
				id="previouscallsformsheet-previouscallport"
				attach="previouscallsformsheet-previouscallport"
				:label="$t('previouscallsformsheet.port')"
				toplabel
				v-model="sheetData"
				modelName="mports"
				reactive
				:modelFormMapping="{ countrycode: 'previouscallcountry', portcode: 'previouscallport' }"
				:itemsToSelect="itemsToSelectPreviousPort"
				:itemValue="['countrycode', 'portcode']"
				:itemText="(item) => `${item.locode} - ${item.portdescription}`"
				:order="{ locode: 'asc' }"
			>
			</pui-select>
		</v-col>
		<v-col cols="6">
			<pui-date-field
				:label="$t('previouscallsformsheet.previouscalldate')"
				v-model="sheetData.previouscalldate"
				toplabel
				required
				@input="changeHour"
				:max="new Date()"
			></pui-date-field>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'previouscallsformsheet',

	components: {},
	data() {
		return {
			sheetname: 'previouscallsformsheet',
			sheetData: {}
		};
	},
	created() {
		this.sheetData = this.$store.getters.getpreviouscallsform;
	},
	mounted() {
		this.$puiEvents.$on('form-openinsert-' + this.sheetname, () => {
			this.sheetData = this.$store.getters.getpreviouscallsform;
		});
		this.$puiEvents.$on('form-submitted-' + this.sheetname, () => {
			this.$puiEvents.$emit('form-datatransfer-' + this.sheetname, this.sheetData);
			this.$store.commit('setpreviouscallsform', {});
		});
		this.$puiEvents.$on('form-cancel-' + this.sheetname, () => {
			this.$store.commit('setpreviouscallsform', {});
			this.sheetData = {};
		});

		this.$puiEvents.$on('form-loadinfo-update-' + this.sheetname, (formData) => {
			this.sheetData = {};
			this.sheetData = formData;
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-previouscallsformsheet-previouscallport', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel !== null) {
				this.sheetData.port = lupaModel.locode + ' - ' + lupaModel.portdescription;
			} else {
				this.sheetData.port = '';
			}
		});
	},
	methods: {
		afterGetData() {},
		changeHour() {
			if (this.sheetData.previouscalldate) {
				const originalDateTime = new Date(this.sheetData.previouscalldate);
				const newDateTime = new Date(originalDateTime.getTime() + 3 * 60 * 60 * 1000);
				this.sheetData.previouscalldate = newDateTime.toISOString();
			} else {
				this.sheetData.previouscalldate = null;
			}
		}
	},
	computed: {
		itemsToSelectPreviousPort() {
			return [{ countrycode: this.sheetData.previouscallcountry, portcode: this.sheetData.previouscallport }];
		}
	},

	destroyed() {
		this.sheetData = {};
		this.$store.commit('setpreviouscallsform', {});
		this.$puiEvents.$off('form-submitted-' + this.sheetname);
		this.$puiEvents.$off('form-loadinfo-update-' + this.sheetname);
		this.$puiEvents.$off('onPuiSelect_selectedItems-previouscallsformsheet-previouscallport');
	}
};
</script>
<style></style>
