<template>
	<v-row class="pui-form-layout">
		<v-col xs12 lg12>
			<v-row dense>
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
					<v-radio-group
						v-model="sheetData.shipactivitylocationind"
						:label="$t('shipactivities.shipactivitylocationind')"
						row
						style="position: relative; top: 4px; font-weight: bold"
						:rules="[sheetData.shipactivitylocationind != null || 'Field is required.']"
					>
						<v-radio
							:checked="selectedOption === $t('shipactivities.port')"
							:label="$t('shipactivities.port')"
							:value="'1'"
							style="margin-left: 70px"
						></v-radio>
						<v-radio :label="$t('shipactivities.location')" :value="'2'"></v-radio>
						<v-radio :label="$t('shipactivities.latlong')" :value="'3'"></v-radio>
					</v-radio-group>
				</v-col>
			</v-row>
			<v-row dense>
				<!-- SHIPACTIVITYSTARTDATE -->
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<pui-date-field
						:id="`shipactivitystartdate-shipactivities`"
						v-model="sheetData.shipactivitystartdate"
						:label="$t('shipactivities.shipactivitystartdate')"
						:disabled="formDisabled"
						required
						toplabel
						@input="changeHour"
						:max="this.sheetData.shipactivityenddate || new Date()"
					></pui-date-field>
				</v-col>

				<!-- SHIPACTIVITYENDDATE -->
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<pui-date-field
						:id="`shipactivityenddate-shipactivities`"
						v-model="sheetData.shipactivityenddate"
						:label="$t('shipactivities.shipactivityenddate')"
						:disabled="formDisabled"
						required
						toplabel
						@input="changeHour2"
						:min="this.sheetData.shipactivitystartdate || new Date()"
						:max="new Date()"
					></pui-date-field>
				</v-col>
				<!-- SHIPACTIVITYCOUNTRY -->
				<v-col v-if="sheetData.shipactivitylocationind == '1'" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
					<pui-select
						:id="`shipactivitycountryport-shipactivities`"
						:attach="`shipactivitycountryport-shipactivities`"
						:label="$t('shipactivities.shipactivitycountry')"
						:disabled="formDisabled"
						toplabel
						clearable
						required="sheetData.shipactivitylocationind == '1'"
						modelName="mports"
						v-model="sheetData"
						reactive
						:itemsToSelect="[{ countrycode: this.sheetData.shipactivitycountry, portcode: this.sheetData.shipactivityport }]"
						:modelFormMapping="{ countrycode: 'shipactivitycountry', portcode: 'shipactivityport' }"
						:itemValue="['countrycode', 'portcode']"
						:itemText="(item) => `${item.location}`"
						:order="{ locode: 'asc' }"
					></pui-select>
				</v-col>
				<!-- SHIPACTIVITYLOCATIONNAME -->
				<v-col v-if="sheetData.shipactivitylocationind == '2'" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
					<pui-text-field
						:id="`shipactivitylocationname-shipactivities`"
						v-model="sheetData.shipactivitylocationname"
						:label="$t('shipactivities.shipactivitylocationname')"
						:disabled="formDisabled"
						toplabel
						required="sheetData.shipactivitylocationind == '2'"
						maxlength="256"
					></pui-text-field>
				</v-col>
				<!-- SHIPACTIVITYLOCATIONLATITUDE -->
				<v-col v-if="sheetData.shipactivitylocationind == '3'" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<pui-number-field
						:id="`shipactivitylocationlatitude-shipactivities`"
						v-model="sheetData.shipactivitylocationlatitude"
						:label="$t('shipactivities.shipactivitylocationlatitude')"
						:disabled="formDisabled"
						toplabel
						suffix="°"
						required="sheetData.shipactivitylocationind == '3'"
						min="-90"
						max="90"
						decimals="3"
					></pui-number-field>
				</v-col>
				<!-- SHIPACTIVITYLOCATIONLONGITUDE -->
				<v-col v-if="sheetData.shipactivitylocationind == '3'" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
					<pui-number-field
						:id="`shipactivitylocationlongitude-shipactivities`"
						v-model="sheetData.shipactivitylocationlongitude"
						:label="$t('shipactivities.shipactivitylocationlongitude')"
						:disabled="formDisabled"
						toplabel
						required="sheetData.shipactivitylocationind == '3'"
						max="180"
						min="-180"
						decimals="3"
						suffix="°"
					></pui-number-field>
				</v-col>
			</v-row>
			<v-row dense>
				<!-- PORTCALLPURPOSECODE-->
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
					<pui-select
						:id="`portcallpurposecode-shipactivities`"
						:attach="`portcallpurposecode-shipactivities`"
						:label="$t('shipactivities.portcallpurposecode')"
						:disabled="formDisabled"
						required
						toplabel
						clearable
						modelName="mportcallpurposes"
						v-model="sheetData"
						reactive
						:itemsToSelect="[{ portcallpurposecode: this.sheetData.portcallpurposecode }]"
						:modelFormMapping="{ portcallpurposecode: 'portcallpurposecode' }"
						:itemValue="['portcallpurposecode']"
						:itemText="(item) => `${item.purposedesc}`"
					></pui-select>
				</v-col>

				<!-- SECURITYMEASURECODE-->
				<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
					<pui-select
						:id="`securitymeasurecode-shipactivities`"
						:attach="`securitymeasurecode-shipactivities`"
						:label="$t('shipactivities.securitymeasurecode')"
						:disabled="formDisabled"
						toplabel
						clearable
						modelName="vlupmsecuritymeasures"
						v-model="sheetData"
						reactive
						:itemsToSelect="[{ securitymeasurecode: this.sheetData.securitymeasurecode }]"
						:modelFormMapping="{ securitymeasurecode: 'securitymeasurecode' }"
						:itemValue="['securitymeasurecode']"
						:itemText="(item) => `${item.securitymeasurecode} - ${item.measuredescription}`"
					></pui-select>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'shipactivitiesformsheet',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			sheetname: 'shipactivitiesformsheet',
			sheetData: {},
			selectedOption: '1'
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		},
		changeHour() {
			if (this.sheetData.shipactivitystartdate) {
				const originalDateTime = new Date(this.sheetData.shipactivitystartdate);
				const newDateTime = new Date(originalDateTime.getTime() + 3 * 60 * 60 * 1000);
				this.sheetData.shipactivitystartdate = newDateTime.toISOString();
			} else {
				this.sheetData.shipactivitystartdate = null;
			}
		},
		changeHour2() {
			if (this.sheetData.shipactivityenddate) {
				const originalDateTime = new Date(this.sheetData.shipactivityenddate);
				const newDateTime = new Date(originalDateTime.getTime() + 3 * 60 * 60 * 1000);
				this.sheetData.shipactivityenddate = newDateTime.toISOString();
			} else {
				this.sheetData.shipactivityenddate = null;
			}
		}
	},
	computed: {},
	mounted() {
		this.$puiEvents.$on('form-submitted-' + this.sheetname, () => {
			this.$puiEvents.$emit('form-datatransfer-' + this.sheetname, this.sheetData);
		});

		this.$puiEvents.$on('form-cancel-' + this.sheetname, () => {
			this.sheetData = {};
		});

		this.$puiEvents.$on('form-loadinfo-update-' + this.sheetname, (formData) => {
			this.sheetData = formData;
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-shipactivitycountryport-shipactivities', (newVal) => {
			var lupaModel = newVal.model;

			if (lupaModel !== null) {
				this.sheetData.locode = lupaModel.locode;
				this.sheetData.portcode = lupaModel.portcode;
				this.sheetData.countrycode = lupaModel.countrycode;
				this.sheetData.location = lupaModel.location;
				this.sheetData.shipactivitycountry = lupaModel.countrycode;
			} else {
				this.sheetData.locode = null;
				this.sheetData.portcode = null;
				this.sheetData.location = null;
			}
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-portcallpurposecode-shipactivities', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel !== null) {
				this.sheetData.purposedesc = lupaModel.purposedesc;
			} else {
				this.sheetData.purposedesc = null;
			}
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-securitymeasurecode-shipactivities', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel !== null) {
				this.sheetData.securitymeasurecode = lupaModel.securitymeasurecode;
				this.sheetData.measuredescription = lupaModel.measuredescription;
			} else {
				this.sheetData.securitymeasurecode = null;
			}
		});
		this.sheetData = this.$store.getters.getshipactivitiesform;
	},
	watch: {
		'sheetData.shipactivitylocationind'() {
			if (this.sheetData.shipactivitylocationind === '1') {
				this.sheetData.shipactivitylocationname = null;
				this.sheetData.shipactivitylocationlatitude = null;
				this.sheetData.shipactivitylocationlongitude = null;
			}
			if (this.sheetData.shipactivitylocationind === '2') {
				this.sheetData.location = this.sheetData.shipactivitylocationname;
				this.sheetData.locode = null;
				this.sheetData.countrycode = null;
				this.sheetData.portcode = null;
				this.sheetData.shipactivitycountry = null;
				this.sheetData.shipactivityport = null;
				this.sheetData.portlocodedesc = null;
				this.sheetData.portdescription = null;
				this.sheetData.shipactivitylocationlatitude = null;
				this.sheetData.shipactivitylocationlongitude = null;
			}
			if (this.sheetData.shipactivitylocationind === '3') {
				this.sheetData.location = this.sheetData.shipactivitylocationlatitude + ' / ' + this.sheetData.shipactivitylocationlongitude;
				this.sheetData.locode = null;
				this.sheetData.countrycode = null;
				this.sheetData.portcode = null;
				this.sheetData.shipactivitycountry = null;
				this.sheetData.portlocodedesc = null;
				this.sheetData.portdescription = null;
			}
		},
		'sheetData.shipactivitylocationname'() {
			if (this.sheetData.shipactivitylocationname != null) {
				this.sheetData.location = this.sheetData.shipactivitylocationname;
				this.sheetData.shipactivitylocationind == '2';
			}
		},
		'sheetData.shipactivitylocationlatitude'() {
			if (this.sheetData.shipactivitylocationlatitude != null || this.sheetData.shipactivitylocationlongitude != null) {
				this.sheetData.shipactivitylocationind == '3';
				this.sheetData.location = this.sheetData.shipactivitylocationlatitude + ' / ' + this.sheetData.shipactivitylocationlongitude;
				this.sheetData.shipactivitylocationname = null;
			}
		},
		'sheetData.shipactivitylocationlongitude'() {
			if (this.sheetData.shipactivitylocationlatitude != null || this.sheetData.shipactivitylocationlongitude != null) {
				this.sheetData.shipactivitylocationind == '3';
				this.sheetData.location = this.sheetData.shipactivitylocationlatitude + ' / ' + this.sheetData.shipactivitylocationlongitude;
				this.sheetData.shipactivitylocationname = null;
			}
		}
	},
	destroyed() {
		this.$puiEvents.$off('form-submitted-' + this.sheetname);
		this.$puiEvents.$off('form-loadinfo-update-' + this.sheetname);
		this.$puiEvents.$off('onPuiSelect_selectedItems-shipactivitycountryport-shipactivities');
		this.$puiEvents.$off('onPuiSelect_selectedItems-portcallpurposecode-shipactivities');
		this.$puiEvents.$off('onPuiSelect_selectedItems-securitymeasurecode-shipactivities');
	}
};
</script>
<style>
#input-334 {
	color: rgba(0, 0, 0, 0.6) !important;
	font-weight: 1000 !important;
	margin-left: 5px !important;
	font-size: 15px !important;
}
</style>
