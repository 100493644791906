export default {
	getlastcallsform: (state) => {
		return state.lastcallsform;
	},
	getshipactivitiesform: (state) => {
		return state.shipactivitiesform;
	},
	getpersonslistform: (state) => {
		return state.personslistform;
	},
	getpreviouscallsform: (state) => {
		return state.previouscallsform;
	},
	// fix login translation errors // state.i18n.te(title) // te method not found
	getTranslate: (state) => {
		return (title, params) => {
			if (state.i18n && state.i18n.t(title)) {
				return state.i18n.t(title, params);
			}
			return title;
		};
	}
};
