<template>
	<v-row class="pui-form-layout">
		<v-row dense>
			<v-col cols="3">
				<pui-text-field
					:label="$t('personslistformsheet.familyname')"
					v-model="sheetData.familyname"
					maxlength="100"
					toplabel
					required
				></pui-text-field>
			</v-col>
			<v-col cols="3">
				<pui-text-field
					:label="$t('personslistformsheet.givennames')"
					v-model="sheetData.givennames"
					maxlength="100"
					toplabel
					required
				></pui-text-field
			></v-col>
			<v-col cols="3">
				<pui-select
					id="personslistformsheet-passengercrewind"
					attach="personslistformsheet-passengercrewind"
					:label="$t('personslistformsheet.passengercrewind')"
					toplabel
					clearable
					v-model="sheetData"
					reactive
					required
					:itemsToSelect="itemsToSelectPassenger"
					:modelFormMapping="{ value: 'passengercrewind' }"
					itemText="text"
					itemValue="value"
					:items="passengercrew"
				></pui-select>
			</v-col>
			<v-col cols="3">
				<pui-select
					id="personslistformsheet-rankcode"
					attach="personslistformsheet-rankcode"
					:label="$t('personslistformsheet.rankcode')"
					toplabel
					v-model="sheetData"
					modelName="vlupmcrewranks"
					reactive
					clearable
					:modelFormMapping="{ rankcode: 'rankcode' }"
					:itemsToSelect="itemsToSelectRankcode"
					:itemValue="['rankcode']"
					itemText="rankdescription"
					:order="{ rankdescription: 'asc' }"
				>
				</pui-select>
			</v-col>
		</v-row>

		<v-row dense>
			<v-col cols="3">
				<pui-date-field
					:label="$t('personslistformsheet.birthdate')"
					v-model="sheetData.birthdate"
					toplabel
					:max="new Date()"
					@input="changeHour"
					required
				></pui-date-field>
			</v-col>
			<v-col cols="3">
				<pui-select
					id="personslistformsheet-gendercode"
					attach="personslistformsheet-gendercode"
					:label="$t('personslistformsheet.gendercode')"
					toplabel
					v-model="sheetData"
					modelName="mgender"
					reactive
					required
					clearable
					:modelFormMapping="{ gendercode: 'gendercode' }"
					:itemsToSelect="itemsToSelectGendercode"
					:itemValue="['gendercode']"
					itemText="genderdescription"
					:order="{ genderdescription: 'asc' }"
				>
				</pui-select>
			</v-col>
			<v-col cols="6">
				<pui-select
					id="personslistformsheet-nationality"
					attach="personslistformsheet-nationality"
					:label="$t('personslistformsheet.nationality')"
					toplabel
					v-model="sheetData"
					modelName="mcountries"
					required
					reactive
					clearable
					:modelFormMapping="{ countrycode: 'nationality' }"
					:itemValue="['countrycode']"
					:itemsToSelect="itemsToSelectNacionality"
					:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
					:order="{ countrydesc: 'asc' }"
				>
				</pui-select>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<pui-select
					id="personslistformsheet-embarkationport"
					attach="personslistformsheet-embarkationport"
					:label="$t('personslistformsheet.embarkationport')"
					toplabel
					v-model="sheetData"
					required
					clearable
					modelName="mports"
					reactive
					:modelFormMapping="{ countrycode: 'embarkationcountry', portcode: 'embarkationport' }"
					:itemsToSelect="itemsToSelectEmbarkationport"
					:itemValue="['countrycode', 'portcode']"
					:itemText="(item) => `${item.locode} - ${item.portdescription}`"
					:order="{ locode: 'asc' }"
				>
				</pui-select>
			</v-col>
			<v-col cols="3">
				<pui-date-field
					:label="$t('personslistformsheet.embarkationdate')"
					v-model="sheetData.embarkationdate"
					toplabel
					@input="changeHour"
					:max="new Date()"
					required
				></pui-date-field>
			</v-col>

			<v-col cols="3">
				<pui-text-field :label="$t('personslistformsheet.illness')" v-model="sheetData.illness" maxlength="100" toplabel></pui-text-field>
			</v-col>
			<v-col cols="3">
				<pui-date-field
					:label="$t('personslistformsheet.symptomsdate')"
					v-model="sheetData.symptomsdate"
					:max="new Date()"
					@input="changeHour"
					toplabel
				></pui-date-field>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<pui-select
					id="personslistformsheet-healthstatusid"
					attach="personslistformsheet-healthstatusid"
					:label="$t('personslistformsheet.healthstatus')"
					toplabel
					v-model="sheetData"
					modelName="vluphealthstatus"
					reactive
					clearable
					required
					:modelFormMapping="{ healthstatusid: 'healthstatusid' }"
					:itemsToSelect="itemsToSelectHealthstatusid"
					:itemValue="['healthstatusid']"
					itemText="description"
					:order="{ description: 'asc' }"
					:fixedFilter="filterHealth"
				>
				</pui-select>
			</v-col>
			<v-col cols="3">
				<pui-select
					id="personslistformsheet-casedisposalid"
					attach="personslistformsheet-casedisposalid"
					:label="$t('personslistformsheet.casedisposalid')"
					toplabel
					v-model="sheetData"
					modelName="vlupcasedisposal"
					reactive
					clearable
					required
					:modelFormMapping="{ casedisposalid: 'casedisposalid' }"
					:itemsToSelect="itemsToSelectCasedisposal"
					:itemValue="['casedisposalid']"
					itemText="description"
					:order="{ description: 'asc' }"
					:key="disposalidkey"
					:fixedFilter="filterCasedisposal"
				>
				</pui-select>
			</v-col>
			<v-col cols="6">
				<pui-text-field
					v-show="showLocation"
					:label="$t('personslistformsheet.evacuationlocation')"
					v-model="sheetData.evacuationlocation"
					maxlength="100"
					toplabel
				></pui-text-field
			></v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<pui-select
					:id="`personslistformsheet-reported`"
					:label="$t('personslistformsheet.reported')"
					v-model="sheetData"
					:modelFormMapping="{ valor: 'reported' }"
					reactive
					required
					toplabel
					clearable
					modelName="vlupyesnoquestions"
					:itemsToSelect="itemsToSelectReported"
					:itemValue="['valor']"
					:itemText="(item) => `${item.opcion}`"
				></pui-select>
			</v-col>
			<v-col cols="3">
				<pui-text-area
					:id="`personslistformsheet-treatment`"
					v-model="sheetData.treatment"
					:label="$t('personslistformsheet.treatment')"
					toplabel
					maxlength="256"
				></pui-text-area>
			</v-col>
			<v-col cols="6">
				<pui-text-area
					:id="`personslistformsheet-commments`"
					v-model="sheetData.commments"
					:label="$t('personslistformsheet.commments')"
					toplabel
					maxlength="256"
				></pui-text-area>
			</v-col>
		</v-row>
	</v-row>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'personslistformsheet',
	mixins: [PuiFormMethodsMixin],

	props: {
		filterValue: {
			type: String,
			required: false
		},
		filterColumn: {
			type: String,
			required: false
		}
	},
	components: {},
	data() {
		return {
			sheetname: 'personslistformsheet',
			sheetData: {},
			healthstatusidkey: 0,
			disposalidkey: 0,
			passengercrew: [
				{
					text: this.$t('personslistformsheet.crewmember'),
					value: '1'
				},
				{
					text: this.$t('personslistformsheet.passenger'),
					value: '0'
				}
			],
			showLocation: true
		};
	},
	created() {
		this.sheetData = this.$store.getters.getpersonslistform;
		this.sheetData[`${this.filterColumn}`] = this.filterValue;
	},
	mounted() {
		//this.sheetData.healthstatusid = parseInt(this.filterValue);
		this.sheetData[`${this.filterColumn}`] = this.filterValue;
		this.$puiEvents.$on('form-submitted-' + this.sheetname, () => {
			this.$puiEvents.$emit('form-datatransfer-' + this.sheetname, this.sheetData);
			this.$store.commit('setpersonslistform', {});
		});
		this.$puiEvents.$on('form-openinsert-' + this.sheetname, () => {
			//this.sheetData = this.$store.getters.getpersonslistform;
			this.sheetData[`${this.filterColumn}`] = this.filterValue;
			this.disposalidkey++;

			this.healthstatusidkey++;
		});

		this.$puiEvents.$on('form-cancel-' + this.sheetname, () => {
			this.sheetData = {};
			this.$store.commit('setpersonslistform', {});
		});

		this.$puiEvents.$on('form-loadinfo-update-' + this.sheetname, (formData) => {
			this.sheetData = {};
			this.sheetData = formData;
			this.sheetData[`${this.filterColumn}`] = this.filterValue;

			this.healthstatusidkey++;
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-personslistformsheet-gendercode', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel) {
				this.sheetData.gender = lupaModel.genderdescription;
			} else {
				this.sheetData.gender = '';
			}
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-personslistformsheet-healthstatusid', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel) {
				this.sheetData.healthstatus = lupaModel.description;
			} else {
				this.sheetData.healthstatus = '';
			}
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-personslistformsheet-nationality', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel) {
				this.sheetData.nationalitydesc = lupaModel.countrydesc;
			} else {
				this.sheetData.nationalitydesc = '';
			}
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-personslistformsheet-embarkationport', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel) {
				this.sheetData.embarkation = lupaModel.portdescription;
			} else {
				this.sheetData.embarkation = '';
			}
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-personslistformsheet-casedisposalid', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel) {
				this.sheetData.casedisposal = lupaModel.description;

				if (this.sheetData.casedisposalid == 2) {
					this.showLocation = true;
				} else {
					this.showLocation = false;
				}
			} else {
				this.showLocation = false;
			}
		});
	},
	watch: {
		'sheetData.passengercrewind'() {
			if (this.sheetData.passengercrewind === '0') {
				this.sheetData.rankcode = null;
			}
		},
		'sheetData.rankcode'() {
			if (this.sheetData.rankcode != null) {
				this.sheetData.passengercrewind = '1';
			}
		},
		'sheetData.casedisposalid'() {
			if (this.sheetData.casedisposalid != 2) {
				this.sheetData.evacuationlocation = null;
			}
		}
	},
	methods: {
		afterGetData() {},
		changeHour() {
			if (this.sheetData.embarkationdate) {
				const originalDateTime = new Date(this.sheetData.embarkationdate);
				const newDateTime = new Date(originalDateTime.getTime() + 3 * 60 * 60 * 1000);
				this.sheetData.embarkationdate = newDateTime.toISOString();
			} else if (this.sheetData.symptomsdate) {
				const originalDateTime = new Date(this.sheetData.symptomsdate);
				const newDateTime = new Date(originalDateTime.getTime() + 3 * 60 * 60 * 1000);
				this.sheetData.symptomsdate = newDateTime.toISOString();
			} else if (this.sheetData.birthdate) {
				const originalDateTime = new Date(this.sheetData.birthdate);
				const newDateTime = new Date(originalDateTime.getTime() + 3 * 60 * 60 * 1000);
				this.sheetData.birthdate = newDateTime.toISOString();
			}
		}
	},
	computed: {
		filterHealth() {
			if (this.filterColumn == 'isdeceased') {
				return {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'healthstatusid', op: 'eq', data: 3 }]
						}
					]
				};
			} else if (this.filterColumn == 'isill') {
				return {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'healthstatusid', op: 'eq', data: 2 }]
						}
					]
				};
			} else {
				return {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'healthstatusid', op: 'ne', data: 3 }]
						}
					]
				};
			}
		},
		filterCasedisposal() {
			if (this.filterColumn == 'isill') {
				return {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'casedisposalid', op: 'eq', data: 1 }]
						}
					]
				};
			} else {
				return null;
			}
		},
		itemsToSelectReported() {
			return [{ valor: this.sheetData.reported }];
		},
		itemsToSelectCasedisposal() {
			return [{ casedisposalid: this.sheetData.casedisposalid }];
		},
		itemsToSelectHealthstatusid() {
			return [{ healthstatusid: this.sheetData.healthstatusid }];
		},
		itemsToSelectEmbarkationport() {
			return [{ countrycode: this.sheetData.embarkationcountry, portcode: this.sheetData.embarkationport }];
		},
		itemsToSelectNacionality() {
			return [{ countrycode: this.sheetData.nationality }];
		},
		itemsToSelectGendercode() {
			return [{ gendercode: this.sheetData.gendercode }];
		},
		itemsToSelectRankcode() {
			return [{ rankcode: this.sheetData.rankcode }];
		},
		itemsToSelectPassenger() {
			return [{ value: this.sheetData.passengercrewind }];
		}
	},
	destroyed() {
		this.sheetData = {};
		this.$store.commit('setpersonslistform', {});
		this.$puiEvents.$off('form-submitted-' + this.sheetname);
		this.$puiEvents.$off('form-loadinfo-update-' + this.sheetname);
		this.$puiEvents.$off('form-openinsert-' + this.sheetname);
		this.$puiEvents.$off('onPuiSelect_selectedItems-personslistformsheet-gendercode');
		this.$puiEvents.$off('onPuiSelect_selectedItems-personslistformsheet-healthstatusid');
		this.$puiEvents.$off('onPuiSelect_selectedItems-personslistformsheet-casedisposalid');
	}
};
</script>
<style></style>
