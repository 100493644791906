import pkg from '../../package.json';
export default {
	puinotifications: {
		// visibleTime in milliseconds + message.length * 15
		// all: {visibleTime: 5000, closeable: false},
		info: { visibleTime: 5000, closeable: false },
		success: { visibleTime: 3000, closeable: false },
		warning: { visibleTime: 5000, closeable: false },
		error: { visibleTime: 5000, closeable: false }
	},
	global: {
		appVersion: pkg.version,
		appDefaultEntryPoint: 'home',
		baseUrl: process.env.VUE_APP_BASE_SERVER_URL,
		baseEsignature: process.env.VUE_APP_BASE_SERVER_URL_ESIGNATURE,
		appContainerHeader: true,
		appLoginDisplayArea: false,
		appLoginActions: true,
		appUserPanelElement: false,
		containerActionHeader: true,
		langContainerActionHeader: false,
		webSocketsEnabled: false,
		showPuiAuditAction: true,
		isMobile: false,
		thousandSeparator: '.'
	},
	httpRequestHeaders: {
		'Accept-Language': 'en'
	},
	login: {
		forgetPassword: false,
		validated2fa: true
	},

	menu: {
		homeBtn: false,
		searchBtn: false,
		infoBtn: false,
		notificationsBtn: false,
		helpBtn: false,
		keepExpanded: false,
		icons: {
			btnUser: 'fak fa-solid-user '
			//fal fa-user-circle fa-kit fa-solid-user
		}
	},
	form: {
		showSaveAndUpdate: true,
		showSaveAndNew: true
	},
	lastcallsform: {},
	shipactivitiesform: {},
	personslistform: {},
	previouscallsform: {},
	puiaudit: {
		auditActionTypes: 'insert,update,delete,newVersion,clearance,cargo'
	}
};
