import Vue from 'vue';
import VueRouter from 'vue-router';

import ImoFalFormsHeader from '@/components/nsw-components/ImoFalFormsHeader';
import NswFormMiniAudit from '@/components/nsw-components/NswFormMiniAudit';
import NswEditionableTable from '@/components/nsw-components/NswEditionableTable';
import NswImportDialog from '@/components/nsw-components/NswImportDialog';

import MshipsversionTemplateForm from '@/components/mshipsversion/MshipsversionTemplateForm';
import AppLoginActions from '@/components/nsw-components/AppLoginActions.vue';
import NswImoFormFooterBtns from '@/components/nsw-components/NswImoFormFooterBtns.vue';
import LastcallsFormSheet from '@/components/securityforms/LastcallsFormSheet.vue';
import ShipactivitiesFormSheet from '@/components/securityforms/ShipactivitiesFormSheet.vue';
import Previouscallsformsheet from '@/components/mdhpreviouscalls/Previouscallsformsheet.vue';
import Personslistformsheet from '@/components/mdhpersonslist/Personslistformsheet.vue';
import NswEventsTemplate from '@/components/nsw-components/NswEventsTemplate.vue';

Vue.use(VueRouter);

// GEN_REGISTER_COMPONENT_START

Vue.component(ImoFalFormsHeader.name, ImoFalFormsHeader);
Vue.component(NswFormMiniAudit.name, NswFormMiniAudit);
Vue.component(NswEditionableTable.name, NswEditionableTable);
Vue.component(NswImportDialog.name, NswImportDialog);
Vue.component(LastcallsFormSheet.name, LastcallsFormSheet);
Vue.component(ShipactivitiesFormSheet.name, ShipactivitiesFormSheet);
Vue.component(MshipsversionTemplateForm.name, MshipsversionTemplateForm);
Vue.component(AppLoginActions.name, AppLoginActions);
Vue.component(NswImoFormFooterBtns.name, NswImoFormFooterBtns);
Vue.component(Previouscallsformsheet.name, Previouscallsformsheet);
Vue.component(Personslistformsheet.name, Personslistformsheet);
Vue.component(NswEventsTemplate.name, NswEventsTemplate);

Vue.component('AppContainerHeader', () => import('@/components/nsw-components/AppContainerHeader'));
Vue.component('cargo-grid', () => import('@/components/cargo/CargoGrid'));
Vue.component('cargo-form', () => import('@/components/cargo/CargoForm'));
Vue.component('cargo-form-header', () => import('@/components/cargo/CargoFormHeader'));
Vue.component('documentextrafields-grid', () => import('@/components/documentextrafields/DocumentExtraFieldsGrid'));
Vue.component('documentextrafieldsportcall-grid', () => import('@/components/documentextrafieldsportcall/DocumentExtraFieldsPortcallGrid'));
Vue.component('imofalformsgeneral-grid', () => import('@/components/imofalformsgeneral/ImoFalFormsGeneralGrid'));
Vue.component('imofalform1menuform', () => import('@/components/imofalform1menu/ImoFalForm1MenuForm'));
Vue.component('imofalform2menuform', () => import('@/components/imofalform2menu/ImoFalForm2MenuForm'));
Vue.component('imofalform3menuform', () => import('@/components/imofalform3menu/ImoFalForm3MenuForm'));
Vue.component('imofalform4menuform', () => import('@/components/imofalform4menu/ImoFalForm4MenuForm'));
Vue.component('imofalform5menuform', () => import('@/components/imofalform5menu/ImoFalForm5MenuForm'));
Vue.component('imofalform6menuform', () => import('@/components/imofalform6menu/ImoFalForm6MenuForm'));
Vue.component('imofalform7menuform', () => import('@/components/imofalform7menu/ImoFalForm7MenuForm'));
Vue.component('driverlistmenuform', () => import('@/components/driverlistmenu/DriverListMenuForm'));
Vue.component('imofalform2-grid', () => import('@/components/imofalform2/Imofalform2Grid'));
Vue.component('imofalform2-form', () => import('@/components/imofalform2/Imofalform2Form'));
Vue.component('imofalform2-form-header', () => import('@/components/imofalform2/Imofalform2FormHeader'));
Vue.component('imofalform3items-grid', () => import('@/components/imofalform3items/Imofalform3itemsGrid'));
Vue.component('imofalform3items-form', () => import('@/components/imofalform3items/Imofalform3itemsForm'));
Vue.component('imofalform3items-form-header', () => import('@/components/imofalform3items/Imofalform3itemsFormHeader'));
Vue.component('imofalform4-grid', () => import('@/components/imofalform4/ImoFalForm4Grid'));
Vue.component('imofalform4-form', () => import('@/components/imofalform4/ImoFalForm4Form'));
Vue.component('imofalform5-grid', () => import('@/components/imofalform5/Imofalform5Grid'));
Vue.component('imofalform5-form', () => import('@/components/imofalform5/Imofalform5Form'));
Vue.component('imofalform5-form-header', () => import('@/components/imofalform5/Imofalform5FormHeader'));
Vue.component('imofalform6-grid', () => import('@/components/imofalform6/Imofalform6Grid'));
Vue.component('imofalform6-form', () => import('@/components/imofalform6/Imofalform6Form'));
Vue.component('imofalform6vehicles-grid', () => import('@/components/imofalform6vehicles/Imofalform6VehiclesGrid'));
Vue.component('imofalform6vehicles-form', () => import('@/components/imofalform6vehicles/Imofalform6VehiclesForm'));
Vue.component('imofalform7-form', () => import('@/components/imofalform7/Imofalform7Form'));
Vue.component('imofalform7-grid', () => import('@/components/imofalform7/Imofalform7Grid'));
Vue.component('driverlist-grid', () => import('@/components/driverlist/DriverListGrid'));
Vue.component('driverlistform', () => import('@/components/driverlist/DriverListForm'));
Vue.component('portcalls-grid', () => import('@/components/portcalls/PortcallsGrid'));
Vue.component('portcalls-form', () => import('@/components/portcalls/PortcallsForm'));
Vue.component('portcallsFormHeader', () => import('@/components/portcalls/PortcallsFormHeader'));
Vue.component('portcallberths-grid', () => import('@/components/portcallberths/PortcallberthsGrid'));
Vue.component('portcallberths-form-header', () => import('@/components/portcallberths/PortcallberthsFormHeader'));
Vue.component('messages-grid', () => import('@/components/messages/MessagesGrid'));
Vue.component('mports-grid', () => import('@/components/mports/MportsGrid'));
Vue.component('mports-form', () => import('@/components/mports/MportsForm'));
Vue.component('mports-form-header', () => import('@/components/mports/MportsFormHeader'));
Vue.component('mships-grid', () => import('@/components/mships/MshipsGrid'));
Vue.component('mships-form', () => import('@/components/mships/MshipsForm'));
Vue.component('mships-form-header', () => import('@/components/mships/MshipsFormHeader'));
Vue.component('mportfacilities-grid', () => import('@/components/mportfacilities/MportfacilitiesGrid'));
Vue.component('mportfacilities-form', () => import('@/components/mportfacilities/MportfacilitiesForm'));
Vue.component('mdocks-grid', () => import('@/components/mdocks/MdocksGrid'));
Vue.component('mdocks-form', () => import('@/components/mdocks/MdocksForm'));
Vue.component('mdocks-form-header', () => import('@/components/mdocks/MdocksFormHeader'));
Vue.component('mshipsversion-grid', () => import('@/components/mshipsversion/MshipsversionGrid'));
Vue.component('mshipsversion-form', () => import('@/components/mshipsversion/MshipsversionForm'));
Vue.component('mshipsversion-form-header', () => import('@/components/mshipsversion/MshipsversionFormHeader'));
Vue.component('shiptecnico-form', () => import('@/components/shiptecnico/ShiptecnicoForm'));
Vue.component('mshiptypes-grid', () => import('@/components/mshiptypes/MshiptypesGrid'));
Vue.component('mshiptypes-form', () => import('@/components/mshiptypes/MshiptypesForm'));
Vue.component('mshiptypes-form-header', () => import('@/components/mshiptypes/MshiptypesFormHeader'));
Vue.component('notifications-grid', () => import('@/components/notifications/NotificationsGrid'));
Vue.component('notifications-form-header', () => import('@/components/notifications/NotificationsFormHeader'));
Vue.component('helptable-grid', () => import('@/components/helptable/HelptableGrid'));
Vue.component('helptable-form-header', () => import('@/components/helptable/HelptableFormHeader'));
Vue.component('mhscodes-grid', () => import('@/components/mhscodes/MhscodesGrid'));
Vue.component('mhscodes-form-header', () => import('@/components/mhscodes/MhscodesFormHeader'));
Vue.component('pollutionalerts-grid', () => import('@/components/pollutionalerts/PollutionalertsGrid'));
Vue.component('pollutionalerts-form', () => import('@/components/pollutionalerts/PollutionalertsForm'));
Vue.component('pollutionalerts-form-header', () => import('@/components/pollutionalerts/PollutionalertsFormHeader'));
Vue.component('pollutionalerts-form-header-modal', () => import('@/components/pollutionalerts/PollutionalertsFormHeaderModal'));
Vue.component('securityforms-grid', () => import('@/components/securityforms/SecurityformsGrid'));
Vue.component('securityforms-form', () => import('@/components/securityforms/SecurityformsForm'));
Vue.component('securityforms-form-header', () => import('@/components/securityforms/SecurityformsFormHeader'));
Vue.component('shipagencytransfers-grid', () => import('@/components/shipagencytransfers/ShipagencytransfersGrid'));
Vue.component('wastenotifications-grid', () => import('@/components/wastenotifications/WastenotificationsGrid'));
Vue.component('wastenotifications-form', () => import('@/components/wastenotifications/WastenotificationsForm'));
Vue.component('wastenotifications-form-header', () => import('@/components/wastenotifications/WastenotificationsFormHeader'));
Vue.component('wastenotificationitems-grid', () => import('@/components/wastenotificationitems/WastenotificationitemsGrid'));
Vue.component('wastenotificationitems-form', () => import('@/components/wastenotificationitems/WastenotificationitemsForm'));
Vue.component('wastenotificationitems-form-header', () => import('@/components/wastenotificationitems/WastenotificationitemsFormHeader'));

Vue.component('mdhealt-grid', () => import('@/components/mdhealt/MdhealtGrid'));
Vue.component('mdhealt-form', () => import('@/components/mdhealt/MdhealtForm'));
Vue.component('mdhealt-form-header', () => import('@/components/mdhealt/MdhealtFormHeader'));
Vue.component('portcallclearancesstatus-grid', () => import('@/components/portcallclearancesstatus/PortcallclearancesstatusGrid'));
Vue.component('portcallclearancesstatus-form', () => import('@/components/portcallclearancesstatus/PortcallclearancesstatusForm'));
Vue.component('portcallclearancesstatus-form-header', () => import('@/components/portcallclearancesstatus/PortcallclearancesstatusFormHeader'));
Vue.component('clearanceactions-grid', () => import('@/components/clearanceactions/ClearanceactionsGrid'));
Vue.component('georepreport-grid', () => import('@/components/georepreport/GeorepreportGrid'));
Vue.component('georepreport-form', () => import('@/components/georepreport/GeorepreportForm'));
Vue.component('georepreport-form-header', () => import('@/components/georepreport/GeorepreportFormHeader'));
Vue.component('mgeoreptypes-grid', () => import('@/components/mgeoreptypes/MgeoreptypesGrid'));
Vue.component('mgeoreptypes-form', () => import('@/components/mgeoreptypes/MgeoreptypesForm'));
Vue.component('distressalerts-grid', () => import('@/components/distressalerts/DistressalertsGrid'));
Vue.component('distressalerts-form', () => import('@/components/distressalerts/DistressalertsForm'));
Vue.component('distressalerts-form-header', () => import('@/components/distressalerts/DistressalertsFormHeader'));
Vue.component('incidentsalerts-form-header', () => import('@/components/distressalerts/IncidentReportFormHeader'));
Vue.component('distressalertssitrep-grid', () => import('@/components/distressalertssitrep/DistressalertssitrepGrid'));
Vue.component('distressalertssitrep-form', () => import('@/components/distressalertssitrep/DistressalertssitrepForm'));
Vue.component('distressalertssitrep-form-header', () => import('@/components/distressalertssitrep/DistressalertssitrepFormHeader'));
Vue.component('mpollutionalertsauthorities-grid', () => import('@/components/mpollutionalertsauthorities/MpollutionalertsauthoritiesGrid'));
Vue.component('mpollutionalertsauthorities-form', () => import('@/components/mpollutionalertsauthorities/MpollutionalertsauthoritiesForm'));
Vue.component('emergencyprocess-grid', () => import('@/components/emergencyprocess/EmergencyprocessGrid'));
Vue.component('emergencyprocess-form', () => import('@/components/emergencyprocess/EmergencyprocessForm'));
Vue.component('emergencyprocess-form-header', () => import('@/components/emergencyprocess/EmergencyprocessFormHeader'));
Vue.component('vcargoclearanceactions-grid', () => import('@/components/vcargoclearanceactions/VcargoclearanceactionsGrid'));
// GEN_REGISTER_COMPONENT_END

const puiRoutes = [
	{
		path: '/',
		redirect: 'home'
	},
	{
		path: 'home',
		component: () => import('@Pui9Base/src/components/PuiWelcomePanel.vue')
	},
	{
		path: 'usersettings',
		component: () => import('@/components/userconfig/UserConfigForm.vue')
	},
	{
		path: 'puiaudit',
		component: () => import('@Pui9Admin/src/components/config/puiaudit/PuiAuditGrid.vue')
	},
	{
		path: 'session',
		component: () => import('@Pui9Admin/src/components/config/puisession/PuiSessionGrid.vue')
	},
	{
		path: 'puiuser',
		component: () => import('@Pui9Admin/src/components/admin/puiuser/PuiUserGrid.vue')
	},
	{
		path: '(.*/)?puiuser/:method/:pk',
		props: true,
		component: () => import('@Pui9Admin/src/components/admin/puiuser/PuiUserForm.vue')
	},
	{
		path: 'puiprofile',
		component: () => import('@Pui9Admin/src/components/admin/puiprofile/PuiProfileGrid.vue')
	},
	{
		path: '(.*/)?puiprofile/:method/:pk',
		props: true,
		component: () => import('@Pui9Admin/src/components/admin/puiprofile/PuiProfileForm.vue')
	},
	{
		path: 'puifunctionality',
		component: () => import('@Pui9Admin/src/components/admin/puifunctionality/PuiFunctionalityGrid.vue')
	},
	{
		path: 'puivariable',
		component: () => import('@Pui9Admin/src/components/config/puivariable/PuiVariableGrid.vue')
	},
	{
		path: '(.*/)?puivariable/:method/:pk',
		props: true,
		component: () => import('@Pui9Admin/src/components/config/puivariable/PuiVariableForm.vue')
	},
	{ path: 'puilanguage', component: () => import('@Pui9Admin/src/components/config/puilanguage/PuiLanguageGrid.vue') },
	{
		path: '(.*/)?puilanguage/:method/:pk',
		props: true,
		component: () => import('@Pui9Admin/src/components/config/puilanguage/PuiLanguageForm.vue')
	},
	{
		path: 'puidocument',
		component: () => import('@Pui9Documents/src/components/PuiDocumentGrid.vue')
	},
	{
		path: '(.*/)?puidocument/:method/:pk',
		props: true,
		component: () => import('@Pui9Documents/src/components/PuiDocumentForm.vue')
	}
];

const appRoutes = [
	// GEN_ROUTES_START
	{
		path: 'documentextrafields',
		component: () => import('@/components/documentextrafields/DocumentExtraFieldsGrid')
	},
	{
		path: '(.*)documentextrafields/:method/:pk',
		props: true,
		component: () => import('@/components/documentextrafields/DocumentExtraFieldsForm')
	},
	{
		path: 'documentextrafieldsportcall',
		component: () => import('@/components/documentextrafieldsportcall/DocumentExtraFieldsPortcallGrid')
	},
	{
		path: '(.*)documentextrafieldsportcall/:method/:pk',
		props: true,
		component: () => import('@/components/documentextrafieldsportcall/DocumentExtraFieldsPortcallForm')
	},
	{
		path: 'imofalform1',
		component: () => import('@/components/imofalform1/ImoFalForm1Grid')
	},
	{
		path: '/imofalform1/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform1/ImoFalForm1Form')
	},
	{
		path: 'imofalform3items',
		component: () => import('@/components/imofalform3items/Imofalform3itemsGrid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalformsgeneral/:methodgeneral/:pkgeneral/imofalform3items/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform3items/Imofalform3itemsForm')
	},
	{
		path: '(.*)imofalform3items/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform3items/Imofalform3itemsForm')
	},
	{
		path: 'imofalform5',
		component: () => import('@/components/imofalform5/Imofalform5Grid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalformsgeneral/:methodgeneral/:pkgeneral/imofalform5/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform5/Imofalform5Form')
	},
	{
		path: '(.*)imofalform5/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform5/Imofalform5Form')
	},
	{
		path: 'imofalform2',
		component: () => import('@/components/imofalform2/Imofalform2Grid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalformsgeneral/:methodgeneral/:pkgeneral/imofalform2/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform2/Imofalform2Form')
	},

	{
		path: '(.*)imofalform2/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform2/Imofalform2Form')
	},
	{
		path: 'imofalform4menu',
		component: () => import('@/components/imofalform4menu/ImoFalForm4MenuGrid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalform4menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform4menu/ImoFalForm4MenuForm')
	},
	{
		path: '(.*)?imofalform4menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform4menu/ImoFalForm4MenuForm')
	},
	{
		path: 'imofalform4',
		component: () => import('@/components/imofalform4/ImoFalForm4Grid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalformsgeneral/:methodgeneral/:pkgeneral/imofalform4/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform4/ImoFalForm4Form')
	},
	{
		path: '(.*)imofalform4/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform4/ImoFalForm4Form')
	},
	{
		path: 'imofalform5menu',
		component: () => import('@/components/imofalform5menu/ImoFalForm5MenuGrid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalform5menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform5menu/ImoFalForm5MenuForm')
	},
	{
		path: '(.*)?imofalform5menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform5menu/ImoFalForm5MenuForm')
	},
	{
		path: 'imofalform2menu',
		component: () => import('@/components/imofalform2menu/ImoFalForm2MenuGrid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalform2menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform2menu/ImoFalForm2MenuForm')
	},
	{
		path: '(.*)?imofalform2menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform2menu/ImoFalForm2MenuForm')
	},
	{
		path: 'imofalform6',
		component: () => import('@/components/imofalform6/Imofalform6Grid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalformsgeneral/:methodgeneral/:pkgeneral/imofalform6/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform6/Imofalform6Form')
	},
	{
		path: '(.*)imofalform6/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform6/Imofalform6Form')
	},

	{
		path: 'imofalform6vehicles',
		component: () => import('@/components/imofalform6vehicles/Imofalform6VehiclesGrid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalformsgeneral/:methodgeneral/:pkgeneral/imofalform6vehicles/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform6vehicles/Imofalform6VehiclesForm')
	},
	{
		path: '(.*)imofalform6vehicles/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform6vehicles/Imofalform6VehiclesForm')
	},
	{
		path: 'portcalls',
		component: () => import('@/components/portcalls/PortcallsGrid')
	},
	{
		path: '(.*)portcalls/:method/:pk',
		props: true,
		component: () => import('@/components/portcalls/PortcallsForm')
	},
	{
		path: '(.*)mportfacilities/:method/:pk',
		props: true,
		component: () => import('@/components/mportfacilities/MportfacilitiesForm')
	},
	{
		path: 'mports',
		component: () => import('@/components/mports/MportsGrid')
	},
	{
		path: '(.*)mports/:method/:pk',
		props: true,
		component: () => import('@/components/mports/MportsForm')
	},
	{
		path: 'mdocks',
		component: () => import('@/components/mdocks/MdocksGrid')
	},
	{
		path: '(.*)mdocks/:method/:pk',
		props: true,
		component: () => import('@/components/mdocks/MdocksForm')
	},
	{
		path: 'mships',
		component: () => import('@/components/mships/MshipsGrid')
	},
	{
		path: '(.*)mships/:method/:pk',
		props: true,
		component: () => import('@/components/mships/MshipsForm')
	},
	{
		path: 'wastenotifications',
		component: () => import('@/components/wastenotifications/WastenotificationsGrid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/wastenotifications/:method/:pk',
		props: true,
		component: () => import('@/components/wastenotifications/WastenotificationsForm')
	},
	{
		path: '(.*)wastenotifications/:method/:pk',
		props: true,
		component: () => import('@/components/wastenotifications/WastenotificationsForm')
	},
	{
		path: 'wastenotificationitems',
		component: () => import('@/components/wastenotificationitems/WastenotificationitemsGrid')
	},
	{
		path: '(.*)wastenotificationitems/:method/:pk',
		props: true,
		component: () => import('@/components/wastenotificationitems/WastenotificationitemsForm')
	},
	{
		path: 'mshipsversion',
		component: () => import('@/components/mshipsversion/MshipsversionGrid')
	},
	{
		path: '(.*)mshipsversion/:method/:pk',
		props: true,
		component: () => import('@/components/mshipsversion/MshipsversionForm')
	},
	{
		path: 'shiptecnico/:method/:pk',
		props: true,
		component: () => import('@/components/shiptecnico/ShiptecnicoForm')
	},
	{
		path: 'imofalform6menu',
		component: () => import('@/components/imofalform6menu/ImoFalForm6MenuGrid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalform6menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform6menu/ImoFalForm6MenuForm')
	},
	{
		path: '(.*)imofalform6menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform6menu/ImoFalForm6MenuForm')
	},
	{
		path: 'imofalform7menu',
		component: () => import('@/components/imofalform7menu/ImoFalForm7MenuGrid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalform7menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform7menu/ImoFalForm7MenuForm')
	},
	{
		path: '(.*)imofalform7menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform7menu/ImoFalForm7MenuForm')
	},
	{
		path: 'imofalform7',
		component: () => import('@/components/imofalform7/Imofalform7Grid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalformsgeneral/:methodgeneral/:pkgeneral/imofalform7/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform7/Imofalform7Form')
	},
	{
		path: '(.*)imofalform7/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform7/Imofalform7Form')
	},
	{
		path: 'driverlistmenu',
		component: () => import('@/components/driverlistmenu/DriverListMenuGrid')
	},

	{
		path: '/portcalls/:parentmethod/:parentpk/driverlistmenu/:method/:pk',
		props: true,
		component: () => import('@/components/driverlistmenu/DriverListMenuForm')
	},
	{
		path: '(.*)driverlistmenu/:method/:pk',
		props: true,
		component: () => import('@/components/driverlistmenu/DriverListMenuForm')
	},
	{
		path: 'driverlist',
		component: () => import('@/components/driverlist/DriverListGrid')
	},
	{
		path: '(.*)driverlist/:method/:pk',
		props: true,
		component: () => import('@/components/driverlist/DriverListForm')
	},

	{
		path: 'portcallberths',
		component: () => import('@/components/portcalls/PortcallsGrid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/portcallberths/:method/:pk',
		props: true,
		component: () => import('@/components/portcallberths/PortcallberthsForm')
	},
	{
		path: '(.*)portcallberths/:method/:pk',
		props: true,
		component: () => import('@/components/portcallberths/PortcallberthsForm')
	},
	{
		path: 'mshiptypes',
		component: () => import('@/components/mshiptypes/MshiptypesGrid')
	},
	{
		path: '(.*)mshiptypes/:method/:pk',
		props: true,
		component: () => import('@/components/mshiptypes/MshiptypesForm')
	},
	{
		path: 'notifications',
		component: () => import('@/components/notifications/NotificationsGrid')
	},
	{
		path: 'helptable',
		component: () => import('@/components/helptable/HelptableGrid')
	},
	{
		path: 'mhscodes',
		component: () => import('@/components/mhscodes/MhscodesGrid')
	},
	{
		path: '(.*/)?imofalformsgeneral/:method/:pk',
		props: true,
		component: () => import('@/components/imofalformsgeneral/ImoFalFormsGeneralForm')
	},
	{
		path: 'imofalform1menu',
		component: () => import('@/components/imofalform1menu/ImoFalForm1MenuGrid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalform1menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform1menu/ImoFalForm1MenuForm')
	},
	{
		path: '(.*/)?imofalform1menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform1menu/ImoFalForm1MenuForm')
	},
	{
		path: 'imofalform3menu',
		component: () => import('@/components/imofalform3menu/ImoFalForm3MenuGrid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/imofalform3menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform3menu/ImoFalForm3MenuForm')
	},
	{
		path: '(.*/)?imofalform3menu/:method/:pk',
		props: true,
		component: () => import('@/components/imofalform3menu/ImoFalForm3MenuForm')
	},
	{
		path: 'securityforms',
		component: () => import('@/components/securityforms/SecurityformsGrid')
	},

	{
		path: '/portcalls/:parentmethod/:parentpk/securityforms/:method/:pk',
		props: true,
		component: () => import('@/components/securityforms/SecurityformsForm')
	},
	{
		path: '(.*)securityforms/:method/:pk',
		props: true,
		component: () => import('@/components/securityforms/SecurityformsForm')
	},
	{
		path: 'mdhealt',
		component: () => import('@/components/mdhealt/MdhealtGrid')
	},
	{
		path: '/portcalls/:parentmethod/:parentpk/mdhealt/:method/:pk',
		props: true,
		component: () => import('@/components/mdhealt/MdhealtForm')
	},
	{
		path: '(.*)mdhealt/:method/:pk',
		props: true,
		component: () => import('@/components/mdhealt/MdhealtForm')
	},
	{
		path: 'portcallclearancesstatus',
		component: () => import('@/components/portcallclearancesstatus/PortcallclearancesstatusGrid')
	},
	{
		path: '(.*)portcallclearancesstatus/:method/:pk',
		props: true,
		component: () => import('@/components/portcallclearancesstatus/PortcallclearancesstatusForm')
	},
	{
		path: 'georepreport',
		component: () => import('@/components/georepreport/GeorepreportGrid')
	},
	{
		path: '(.*)georepreport/:method/:pk',
		props: true,
		component: () => import('@/components/georepreport/GeorepreportForm')
	},
	{
		path: 'mgeoreptypes',
		component: () => import('@/components/mgeoreptypes/MgeoreptypesGrid')
	},
	{
		path: '(.*)mgeoreptypes/:method/:pk',
		props: true,
		component: () => import('@/components/mgeoreptypes/MgeoreptypesForm')
	},
	{
		path: 'cargo',
		component: () => import('@/components/cargo/CargoGrid')
	},
	{
		path: '(.*)cargo/:method/:pk',
		props: true,
		component: () => import('@/components/cargo/CargoForm')
	},
	{
		path: 'messages',
		component: () => import('@/components/messages/MessagesGrid')
	},
	{
		path: 'distressalerts',
		component: () => import('@/components/distressalerts/DistressalertsGrid')
	},
	{
		path: '(.*)distressalerts/:method/:pk',
		props: true,
		component: () => import('@/components/distressalerts/DistressalertsForm')
	},
	{
		path: 'pollutionalerts',
		component: () => import('@/components/pollutionalerts/PollutionalertsGrid')
	},
	{
		path: '(.*)pollutionalerts/:method/:pk',
		props: true,
		component: () => import('@/components/pollutionalerts/PollutionalertsForm')
	},
	{
		path: 'distressalertssitrep',
		component: () => import('@/components/distressalertssitrep/DistressalertssitrepGrid')
	},
	{
		path: '(.*)distressalertssitrep/:method/:pk',
		props: true,
		component: () => import('@/components/distressalertssitrep/DistressalertssitrepForm')
	},
	{
		path: 'mpollutionalertsauthorities',
		component: () => import('@/components/mpollutionalertsauthorities/MpollutionalertsauthoritiesGrid')
	},
	{
		path: '(.*)mpollutionalertsauthorities/:method/:pk',
		props: true,
		component: () => import('@/components/mpollutionalertsauthorities/MpollutionalertsauthoritiesForm')
	},
	{
		path: 'shipagencytransfers',
		component: () => import('@/components/shipagencytransfers/ShipagencytransfersGrid')
	},
	{
		path: 'emergencyprocess',
		component: () => import('@/components/emergencyprocess/EmergencyprocessGrid')
	},
	{
		path: '(.*)emergencyprocess/:method/:pk',
		props: true,
		component: () => import('@/components/emergencyprocess/EmergencyprocessForm')
	}

	// GEN_ROUTES_END
];

const puiNotFound = [{ path: '*', component: () => import('@Pui9Base/src/components/PuiNotFound') }];

function setAuthRequired(route) {
	return { ...route, meta: { ...route.meta, requiresAuth: true } };
}

const mergedRouter = {
	mode: 'history',
	hash: false,
	base: '/nsw',
	routes: [
		{
			path: '/login',
			component: () => import('@Pui9Base/src/components/PuiIntroLayout')
		},
		{
			path: '/resetPassword',
			component: () => import('@/components/nsw-components/NswUserResetPassword')
		},
		{
			path: '/keycloaklogin',
			component: () => import('@Pui9Login/src/components/PuiKeycloakLogin')
		},
		{
			path: '/nswnewuserpass',
			component: () => import('@/components/nsw-components/NswNewUserPass')
		},
		{
			path: `/`,
			component: () => import('@Pui9Base/src/components/PuiBaseLayout'),
			children: puiRoutes.concat(appRoutes).concat(puiNotFound).map(setAuthRequired)
		}
	]
};

export default new VueRouter(mergedRouter);
