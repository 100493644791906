<template>
	<v-layout wrap class="puiminiaudit mt-2 mb-4">
		<v-flex xs12>
			<label>{{ $t('form.miniaudit.created') }}</label>
			<label>{{ returnData(internalValue.creausr) }}{{ $t('form.miniaudit.at') }}</label>
			<label>{{ returnData(internalValue.creadate) }}</label>
		</v-flex>
		<v-flex xs12>
			<label>{{ $t('form.miniaudit.modified') }}</label>
			<label>{{ returnData(internalValue.modusr) }}{{ $t('form.miniaudit.at') }}</label>
			<label>{{ returnData(internalValue.moddate) }}</label>
		</v-flex>
	</v-layout>
</template>

<script>

export default {
	name: 'NswFormMiniAudit',
	props: {
		model: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			internalValue: {}
		};
	},
	created() {
		this.internalValue = Object.assign({}, this.model);

		if (this.model.moddate !== null && this.model.moddate !== undefined) {
			this.internalValue.moddate = this.$moment(this.model.moddate).format(this.dateTimeFormat);
		}
		if (this.model.creadate !== null && this.model.creadate !== undefined) {
			this.internalValue.creadate = this.$moment(this.model.creadate).format(this.dateTimeFormat);
		}
	},
	computed: {
		dateTimeFormat() {
			return `${this.$store.getters.dateFormat} ${this.$store.getters.timeFormat}`;
		}
	},
	methods: {
		returnData(value) {
			if (!value) {
				return '#####';
			}
			return value;
		}
	}
};
</script>
