<template>
	<div class="timeline mt-4">
		<v-timeline :dense="$vuetify.breakpoint.smAndDown">
			<v-timeline-item
				v-for="(item, index) in items"
				:key="index"
				:left="item.clearancetypeid === cardRight"
				:right="item.clearancetypeid === cardLeft"
				:icon="getIcon(item.clearanceactiontypeid)"
			>
				<v-card class="elevation-2">
					<v-card-title :class="getCardTitleClass(item.clearanceactiontypeid)" style="word-break: break-word">{{
						item.clearactiondesc
					}}</v-card-title>
					<v-card-text>
						<div>Date: {{ formatDate(item.date) }}</div>
						<div>Organization: {{ item.usrorganization }}</div>
						<div>Remarks: {{ item.observations || 'No remarks' }}</div>
					</v-card-text>
				</v-card>

				<template v-slot:opposite>
					<span v-show="!currentRouteName">{{ item.clearancetypeid === cardRight ? 'Arrival' : 'Departure' }}</span>
					<span v-show="currentRouteName">{{ item.clearancetypeid === cardRight ? 'Unloading' : 'Loading' }}</span>
				</template>
			</v-timeline-item>
		</v-timeline>
	</div>
</template>

<script>
export default {
	name: 'nsw-events-template',
	props: {
		items: {
			type: Array,
			required: true
		},
		cardLeft: {
			type: Number,
			required: false
		},
		cardRight: {
			type: Number,
			required: false
		}
	},
	methods: {
		formatDate(dateString) {
			if (!dateString) return ''; // Manejo para fechas nulas o indefinidas
			const date = new Date(dateString);
			return date.toLocaleString();
		},
		/*formatDate(dateString) {
			if (!dateString) return ''; // Manejo para fechas nulas o indefinidas
			return this.$moment.utc(dateString).utcOffset('+0400').format('DD-MM-YYYY HH:mm');
		},
		formatDate(dateString) {
			if (!dateString) return ''; // Manejo para fechas nulas o indefinidas
			return this.$moment(dateString).utc().format('DD-MM-YYYY HH:mm');
		}*/ getIcon(clearanceactiontypeid) {
			if (this.arrayShag.includes(clearanceactiontypeid)) {
				return 'fa-kit fa-solid-ship';
			} else if (this.arrayPpof.includes(clearanceactiontypeid)) {
				return 'fa-kit fa-solid-authority';
			} else if (this.arrayCargo.includes(clearanceactiontypeid)) {
				return 'fas fa-truck-container';
			} else {
				return 'fa-kit fa-solid-ship'; // Icono predeterminado en caso de que no coincida con ninguno
			}
		},
		getPosition(item) {
			return {
				left: item.clearancetypeid === this.cardLeft,
				right: item.clearancetypeid === this.cardRight
			};
		},
		getCardTitleClass(clearanceactiontypeid) {
			if (
				clearanceactiontypeid == 24 ||
				clearanceactiontypeid == 25 ||
				clearanceactiontypeid == 29 ||
				clearanceactiontypeid == 30 ||
				clearanceactiontypeid == 37 ||
				clearanceactiontypeid == 41
			) {
				return 'text-h6 card-title-shag';
			} else {
				return this.arrayShag.includes(clearanceactiontypeid) ? 'text-h6 card-title-shag' : 'text-h6 card-title-ppof';
			}
		}
	},
	computed: {
		arrayCargo() {
			return [24, 25, 29, 30];
		},
		arrayShag() {
			return [1, 2, 5, 8, 9, 12, 15, 16, 20, 21, 36, 40, 41, 37, 45, 46];
		},
		arrayPpof() {
			return [3, 4, 6, 7, 10, 11, 13, 14, 17, 18, 19, 22, 23, 26, 27, 28, 31, 32, 33, 34, 35, 38, 39, 42, 43, 44];
		},
		currentRouteName() {
			return this.$route.fullPath.startsWith('/cargo/');
		}
	},
	created() {
		/** fa-kit fa-solid-ship
		 * fa-kit fa-solid-company
		 * fa-kit fa-solid-authority
		 * fak fa-solid-authority
		 */
	}
};
</script>
