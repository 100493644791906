<template>
	<v-layout wrap class="pui-form-layout">
		<v-flex xs12 md4>
			<pui-field-set :title="getTitleGeneralData">
				<v-layout wrap>
					<v-flex xs12 md6 lg6 x6 v-if="!isCreatingElement" align-self-center>
						<!-- INACTIVEIND -->
						<pui-checkbox
							:id="`inactiveind-mships`"
							v-model="parentObject.model.inactiveind"
							:label="$t('mships.inactiveind')"
							:disabled="formDisabled"
							required
							true-value="1"
							false-value="0"
						></pui-checkbox>
					</v-flex>
					<v-flex xs12 md6 lg6 x6 v-if="!this.isCreatingElement">
						<!-- SHIPSTATUSID -->
						<pui-select
							:id="`shipstatusid-mships`"
							:attach="`shipstatusid-mships`"
							:label="$t('mships.shipstatusid')"
							:disabled="formDisabled"
							required
							toplabel
							clearable
							modelName="mshipstatus"
							v-model="parentObject.model"
							reactive
							:itemsToSelect="shipstatusidItemsToSelect"
							:modelFormMapping="{ shipstatusid: 'shipstatusid' }"
							:itemValue="['shipstatusid']"
							:itemText="(item) => `${item.desc}`"
						></pui-select>
					</v-flex>
					<v-flex xs12 md6 lg6 x6 v-if="!isCreatingElement">
						<!-- shipid -->
						<pui-number-field
							:id="`shipid-mships`"
							v-model="parentObject.model.shipid"
							:label="$t('mships.shipid')"
							:disabled="!this.isCreatingElement"
							required
							toplabel
							integer
							min="0"
							max="99999"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md6 lg6 x6 v-if="!isCreatingElement">
						<!-- VERSION -->
						<pui-number-field
							:id="`version-mships`"
							v-model="parentObject.model.version"
							:label="$t('mships.version')"
							:disabled="!this.isCreatingElement"
							required
							toplabel
							integer
							min="0"
							max="99999"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md6 lg6 x6>
						<!-- INIVALIDITY -->
						<pui-date-field
							:id="`inivalidity-mships`"
							v-model="parentObject.model.inivalidity"
							:label="$t('mships.inivalidity')"
							:disabled="!this.isCreatingElement"
							required
							toplabel
						></pui-date-field>
					</v-flex>
					<v-flex xs12 md6 lg6 x6 v-if="!isCreatingElement">
						<!-- ENDVALIDITY -->
						<pui-date-field
							:id="`endvalidity-mships`"
							v-model="parentObject.model.endvalidity"
							:label="$t('mships.endvalidity')"
							:min="parentObject.model.inivalidity"
							disabled
							toplabel
						></pui-date-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md12 lg12 x12>
						<!-- SHIPNAME -->
						<pui-text-field
							:id="`shipname-mships`"
							v-model="parentObject.model.shipname"
							:label="$t('mships.shipname')"
							:disabled="formDisabled"
							required
							toplabel
							maxlength="35"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md12 lg12 x12>
						<!-- SHIPTYPECODE -->
						<pui-select
							:id="`shiptypecode-mships`"
							:attach="`shiptypecode-mships`"
							:label="$t('mships.shiptypecode')"
							:disabled="formDisabled"
							required
							toplabel
							clearable
							modelName="mshiptypes"
							v-model="parentObject.model"
							reactive
							:itemsToSelect="shiptypecodeItemsToSelect"
							:modelFormMapping="{ shiptypecode: 'shiptypecode' }"
							:itemValue="['shiptypecode']"
							:itemText="(item) => `${item.shiptypecode} - ${item.shiptypedesc}`"
						></pui-select>
					</v-flex>
					<v-flex xs12 md12 lg6 x6>
						<!-- IMO -->
						<pui-text-field
							:id="`imo-mships`"
							v-model="parentObject.model.imo"
							:label="$t('mships.imo')"
							:disabled="formDisabled"
							required
							toplabel
							:rules="[($v) => (!!$v && $v.length >= 7) || 'The minimum length of the attribute IMO is 7 character(e)']"
							maxlength="7"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md12 lg6 x6>
						<!-- CALLSIGN -->
						<pui-text-field
							:id="`callsign-mships`"
							v-model="parentObject.model.callsign"
							:label="$t('mships.callsign')"
							:disabled="formDisabled"
							required
							toplabel
							maxlength="7"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md12 lg12 x12>
						<!-- FLAG -->
						<pui-select
							:id="`flag-mships`"
							:attach="`flag-mships`"
							:label="$t('mships.flag')"
							:disabled="formDisabled"
							required
							toplabel
							clearable
							modelName="mcountries"
							v-model="parentObject.model"
							reactive
							:itemsToSelect="flagItemsToSelect"
							:modelFormMapping="{ countrycode: 'flag' }"
							:itemValue="['countrycode']"
							:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
						></pui-select>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md12 lg6 x6>
						<!-- MMSI -->
						<pui-text-field
							:id="`mmsi-mships`"
							v-model="parentObject.model.mmsi"
							:label="$t('mships.mmsi')"
							:disabled="formDisabled"
							required
							toplabel
							maxlength="9"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md12 lg6 x2>
						<!-- INMARSATCALLNR -->
						<pui-text-field
							:id="`inmarsatcallnr-mships`"
							v-model="parentObject.model.inmarsatcallnr"
							:label="$t('mships.inmarsatcallnr')"
							:disabled="formDisabled"
							required
							toplabel
							maxlength="50"
						></pui-text-field>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-flex>
		<v-flex xs12 md8>
			<pui-field-set :title="getTitleTechnicalData">
				<v-layout wrap>
					<v-flex xs12 md3 lg3 x3>
						<!-- LENGTH -->
						<pui-number-field
							:id="`length-mships`"
							v-model="parentObject.model.length"
							:label="$t('mships.length')"
							:disabled="formDisabled"
							required
							toplabel
							maxlength="6"
							decimals="2"
							suffix="m"
							min="1"
							max="999.99"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 lg3 xl3>
						<!-- BEAM -->
						<pui-number-field
							:id="`beam-mships`"
							v-model="parentObject.model.beam"
							:label="$t('mships.beam')"
							:disabled="formDisabled"
							required
							toplabel
							suffix="m"
							maxlength="5"
							decimals="2"
							min="1"
							max="999.99"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 lg3 xl3>
						<!-- MAXDRAFT -->
						<pui-number-field
							:id="`maxdraft-mships`"
							v-model="parentObject.model.maxdraft"
							:label="$t('mships.maxdraft')"
							:disabled="formDisabled"
							required
							suffix="m"
							toplabel
							maxlength="6"
							decimals="2"
							min="1"
							max="999.99"
						></pui-number-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md3 lg3 x3>
						<!-- GROSSTONNAGE -->
						<pui-number-field
							:id="`grosstonnage-mships`"
							v-model="parentObject.model.grosstonnage"
							:label="$t('mships.grosstonnage')"
							:disabled="formDisabled"
							required
							toplabel
							suffix="t"
							maxlength="11"
							decimals="3"
							min="1"
							max="9999999.999"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 lg3 x3>
						<!-- REDGROSSTONNAGE -->
						<pui-number-field
							:id="`redgrosstonnage-mships`"
							v-model="parentObject.model.redgrosstonnage"
							:label="$t('mships.redgrosstonnage')"
							:disabled="formDisabled"
							required
							toplabel
							suffix="t"
							min="1"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 lg3 x3>
						<!-- NETTONNAGE -->
						<pui-number-field
							:id="`nettonnage-mships`"
							v-model="parentObject.model.nettonnage"
							:label="$t('mships.nettonnage')"
							:disabled="formDisabled"
							toplabel
							suffix="t"
							maxlength="11"
							decimals="3"
							min="1"
							max="9999999.999"
						></pui-number-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md3 lg3 x3>
						<!-- HULLTYPEID -->
						<pui-select
							:id="`hulltypeid-mships`"
							:attach="`hulltypeid-mships`"
							:label="$t('mships.hulltypeid')"
							:disabled="formDisabled"
							toplabel
							clearable
							modelName="mhulltypes"
							v-model="parentObject.model"
							reactive
							:itemsToSelect="hulltypeidItemsToSelect"
							:modelFormMapping="{ hulltypeid: 'hulltypeid' }"
							:itemValue="['hulltypeid']"
							:itemText="(item) => `${item.desc}`"
						></pui-select>
					</v-flex>
					<v-flex xs12 md3 lg3 x3>
						<!-- MOORINGLINESNUM -->
						<pui-number-field
							:id="`mooringlinesnum-mships`"
							v-model="parentObject.model.mooringlinesnum"
							:label="$t('mships.mooringlinesnum')"
							:disabled="formDisabled"
							toplabel
							integer
							min="1"
							max="99999"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 lg3 x3>
						<!-- MOORINGLINETYPEID -->
						<pui-select
							:id="`mooringlinetypeid-mships`"
							:attach="`mooringlinetypeid-mships`"
							:label="$t('mships.mooringlinetypeid')"
							:disabled="formDisabled"
							toplabel
							clearable
							modelName="mmooringlinetypes"
							v-model="parentObject.model"
							reactive
							:itemsToSelect="mooringlinetypeidItemsToSelect"
							:modelFormMapping="{ mooringlinetypeid: 'mooringlinetypeid' }"
							:itemValue="['mooringlinetypeid']"
							:itemText="(item) => `${item.desc}`"
						></pui-select>
					</v-flex>
					<v-flex xs12 md3 lg3 x3>
						<!-- MOORINGWINCHESNUM -->
						<pui-number-field
							:id="`mooringwinchesnum-mships`"
							v-model="parentObject.model.mooringwinchesnum"
							:label="$t('mships.mooringwinchesnum')"
							:disabled="formDisabled"
							toplabel
							integer
							min="1"
							max="99999"
						></pui-number-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md3 lg3 x3>
						<!-- POWER -->
						<pui-number-field
							:id="`power-mships`"
							v-model="parentObject.model.power"
							:label="$t('mships.power')"
							:disabled="formDisabled"
							required
							suffix="kW"
							toplabel
							min="1"
							max="99999"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 lg3 x3>
						<!-- USEDFUEL -->
						<pui-number-field
							:id="`usedfuel-mships`"
							v-model="parentObject.model.usedfuel"
							:label="$t('mships.usedfuel')"
							:disabled="formDisabled"
							required
							toplabel
							maxlength="100"
							max="99999"
							min="1"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 lg3 x3
						><v-flex xs12 md3 lg3 x3></v-flex>
						<!-- NUMTHRUSTER -->
						<pui-number-field
							:id="`numthruster-mships`"
							v-model="parentObject.model.numthruster"
							:label="$t('mships.numthruster')"
							:disabled="formDisabled"
							required
							toplabel
							integer
							min="1"
							max="99999"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 lg3 x3>
						<!-- THRUSTERPOSITION -->
						<pui-select
							:id="`thrusterposition-mships`"
							:attach="`thrusterposition-mships`"
							:label="$t('mships.thrusterposition')"
							itemText="text"
							itemValue="value"
							:disabled="formDisabled"
							required
							toplabel
							clearable
							v-model="parentObject.model"
							reactive
							:items="positions"
							:itemsToSelect="thrusterpositionItemsToSelect"
							:modelFormMapping="{ value: 'thrusterposition' }"
						></pui-select>
					</v-flex>
				</v-layout>

				<v-layout wrap>
					<v-flex xs3 md3 lg3 x3>
						<!-- SBTIND -->
						<pui-checkbox
							:id="`sbtind-mships`"
							v-model="parentObject.model.sbtind"
							:label="$t('mships.sbtind')"
							:disabled="formDisabled"
							true-value="1"
							false-value="2"
							style="margin-bottom: 20px"
						></pui-checkbox>
					</v-flex>
					<v-flex xs12 md4 lg4 x4 v-if="parentObject.model.sbtind == 1">
						<!-- SBTNUMB -->
						<pui-number-field
							:id="`sbtnumb-mships`"
							v-model="parentObject.model.sbtnumb"
							:label="$t('mships.sbtnumb')"
							:disabled="formDisabled"
							toplabel
							integer
							min="1"
							max="99999"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md4 lg4 x4 v-if="parentObject.model.sbtind == 1">
						<!-- SBTCAPACITY -->
						<pui-number-field
							:id="`sbtcapacity-mships`"
							v-model="parentObject.model.sbtcapacity"
							:label="$t('mships.sbtcapacity')"
							:disabled="formDisabled"
							toplabel
							decimals="6"
							max="99999"
							min="1"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md4 lg4 x4 v-if="parentObject.model.sbtind == 1">
						<!-- MEASUREMENTUNITCODE -->
						<pui-select
							:id="`measurementunitcode-mships`"
							:attach="`measurementunitcode-mships`"
							:label="$t('mships.measurementunitcode')"
							:disabled="formDisabled"
							required
							toplabel
							clearable
							modelName="mmeasurementunits"
							v-model="parentObject.model"
							reactive
							:itemsToSelect="measurementUnitCodeItemsToSelect"
							:modelFormMapping="{ measurementunitcode: 'measurementunitcode' }"
							:itemValue="['measurementunitcode']"
							:itemText="(item) => `${item.measurementunitsymbol} - ${item.typedescription}`"
						></pui-select>
					</v-flex>
				</v-layout>

				<v-layout wrap>
					<v-flex xs12 md12 lg12 x12 v-if="!isCreatingElement && !parentObject.model.shipstatusid === 2">
						<!-- REJECTIONREASON -->
						<pui-text-area
							:id="`rejectionreason-mships`"
							v-model="parentObject.model.rejectionreason"
							:label="$t('mships.rejectionreason')"
							:disabled="formDisabled"
							toplabel
						></pui-text-area>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-flex>
		<v-flex xs12>
			<pui-field-set :title="getTitleAdditionalData">
				<v-layout wrap>
					<!-- registryportcountry -->
					<v-flex xs12 md4 lg4 x4>
						<pui-select
							id="`mships-registryportcountry`"
							attach="mships-registryportcountry"
							:label="$t('mships.registryportcountry')"
							toplabel
							:disabled="formDisabled"
							reactive
							required
							clearable
							v-model="parentObject.model"
							modelName="mports"
							:modelFormMapping="{ countrycode: 'certregistrycountry', portcode: 'certregistryport' }"
							:itemsToSelect="[{ countrycode: parentObject.model.certregistrycountry, portcode: parentObject.model.certregistryport }]"
							:itemValue="['countrycode', 'portcode']"
							:itemText="(item) => `${item.locode} - ${item.portdescription}`"
							:order="{ locode: 'asc' }"
						></pui-select>
					</v-flex>

					<!-- certregistrynumber -->
					<v-flex xs12 md4 lg4 x4>
						<pui-text-field
							:id="`mships-certregistrynumber`"
							v-model="parentObject.model.certregistrynumber"
							:label="$t('mships.certregistrynumber')"
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md12 lg2 x2>
						<!-- CONSTRUCTIONDATE -->
						<pui-date-field
							:id="`constructiondate-mships`"
							v-model="parentObject.model.constructiondate"
							:label="$t('mships.constructiondate')"
							:disabled="formDisabled"
							toplabel
							:max="getMaxDate()"
						></pui-date-field>
					</v-flex>
					<!-- certregistrydate -->
					<v-flex xs12 md4 lg2 x2>
						<pui-date-field
							:id="`mships-certregistrydate`"
							:label="$t('mships.certregistrydate')"
							toplabel
							:disabled="formDisabled"
							v-model="parentObject.model.certregistrydate"
						></pui-date-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md4 lg4 x4>
						<!-- CLASSSOCIETY -->
						<pui-text-field
							:id="`classsociety-mships`"
							v-model="parentObject.model.classsociety"
							:label="$t('mships.classsociety')"
							:disabled="formDisabled"
							required
							toplabel
							maxlength="100"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md8 lg8 x8>
						<!-- CLASSNOTATION -->
						<pui-text-field
							:id="`classnotation-mships`"
							v-model="parentObject.model.classnotation"
							:label="$t('mships.classnotation')"
							:disabled="formDisabled"
							required
							toplabel
						></pui-text-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md12 lg12 x12>
						<!-- OBSERVATIONS -->
						<pui-text-area
							:id="`observations-mships`"
							v-model="parentObject.model.observations"
							:label="$t('mships.observations')"
							:disabled="formDisabled"
							toplabel
						></pui-text-area>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'mshipsversiontemplateform',

	components: {},
	data() {
		return {
			positions: [
				{
					text: this.$t('mships.Bow'),
					value: 'Bow'
				},
				{
					text: this.$t('mships.Stern'),
					value: 'Stern'
				}
			]
		};
	},
	methods: {
		afterGetData() {},
		getMaxDate() {
			const currentDate = new Date();
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1; // Meses de 0 a 11
			const day = currentDate.getDate();

			// Formateamos la fecha en el formato YYYY-MM-DD
			return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
		}
	},
	props: {
		modelName: {
			type: String,
			required: true
		},
		parentObject: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		},
		objectkey: {
			type: Number,
			required: false
		}
	},
	computed: {
		shiptypecodeItemsToSelect() {
			return [{ shiptypecode: this.parentObject.model.shiptypecode }];
		},
		flagItemsToSelect() {
			return [{ countrycode: this.parentObject.model.flag }];
		},
		shipstatusidItemsToSelect() {
			return [{ shipstatusid: this.parentObject.model.shipstatusid }];
		},
		thrusterpositionItemsToSelect() {
			return [{ value: this.parentObject.model.thrusterposition }];
		},
		measurementUnitCodeItemsToSelect() {
			return [{ measurementunitcode: this.parentObject.model.measurementunitcode }];
		},
		hulltypeidItemsToSelect() {
			return [{ hulltypeid: this.parentObject.model.hulltypeid }];
		},
		mooringlinetypeidItemsToSelect() {
			return [{ mooringlinetypeid: this.parentObject.model.mooringlinetypeid }];
		},
		getTitleTechnicalData() {
			return this.$t('mships.technicalData');
		},
		getTitleAdditionalData() {
			return this.$t('mships.additionalData');
		},
		getTitleGeneralData() {
			return this.$t('mships.generalData');
		}
	},
	created() {}
};
</script>
