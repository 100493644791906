<template>
	<div>
		<a
			href="#"
			style="text-decoration: none; margin-left: 40px"
			@keyup.enter="dialogs.forgetPassword.show = true"
			@click="dialogs.forgetPassword.show = true"
			>{{ $t('forgetpassword.link') }}</a
		>

		<pui-modal-dialog
			v-if="dialogs.forgetPassword.show"
			:cancelText="$t('form.cancel')"
			:titleText="dialogs.forgetPassword.titleText"
			:messageText="dialogs.forgetPassword.messageText"
			:okText="dialogs.forgetPassword.okText"
			:disableOk="dialogs.forgetPassword.disableOk"
			dialogName="appForgetPasswordDialog"
			ref="appForgetPasswordDialog"
		>
			<template v-slot:message>
				<pui-text-field v-model="dialogs.forgetPassword.usremail" noeditable updateValueRealtime maxlength="100"></pui-text-field>
			</template>
		</pui-modal-dialog>
		<!-- Frase adicional -->
		<div>
			<hr />
			<p>
				{{ $t('dataprivacypolicy.mens') }}
				<a :href="$t('dataprivacypolicy.link')"> {{ $t('dataprivacypolicy.linktext') }}</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'apploginactions',
	data() {
		return {
			requestResetPasswordUrl: '/nswusers/requestResetPassword',
			dialogs: {
				forgetPassword: {
					show: false,
					titleText: this.$t('forgetpassword.title'),
					messageText: this.$t('forgetpassword.message'),
					okText: this.$t('forgetpassword.ok'),
					disableOk: false,
					usremail: null
				}
			}
		};
	},
	mounted() {
		this.subscribeToAppForgetPasswordDialogEvent();
	},
	beforeDestroy() {
		this.unsubscribeToAppForgetPasswordDialogEvent();
	},
	computed: {
		showForgetPassword() {
			return this.$store.state.login.forgetPassword;
		}
	},
	methods: {
		subscribeToAppForgetPasswordDialogEvent() {
			this.$puiEvents.$on('pui-modalDialog-appForgetPasswordDialog-ok', () => {
				this.onResetPressSendMail();
			});
			this.$puiEvents.$on('pui-modalDialog-appForgetPasswordDialog-cancel', () => {
				this.dialogs.forgetPassword.show = false;
			});
		},
		unsubscribeToAppForgetPasswordDialogEvent() {
			this.$puiEvents.$off('pui-modalDialog-appForgetPasswordDialog-ok');
			this.$puiEvents.$off('pui-modalDialog-appForgetPasswordDialog-cancel');
		},
		async onResetPressSendMail() {
			const usrEmail = this.dialogs.forgetPassword.usremail;

			if (!usrEmail || usrEmail === '') {
				return this.$puiNotify.error(this.$t('forgetpassword.usremail.label'));
			}

			try {
				await this.$puiRequests.getRequest(this.requestResetPasswordUrl, { usrEmail });

				this.dialogs.forgetPassword.show = false;
				this.$puiNotify.success(this.$t('forgetpassword.usremail.response'));
			} catch (error) {
				this.$store.dispatch('puiRequestShowServerError', { error });
			}
		}
	}
};
</script>
