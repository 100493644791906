<template>
	<div class="imofalformsheader puiformheader">
		<v-row dense>
			<v-col v-show="this.value.imofalformtypeid != 3" class="col-6 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
				<pui-field :label="this.$t('header.documentid')" :value="getDocumentID"></pui-field>
			</v-col>
			<v-col v-show="this.value.imofalformtypeid == 3" class="col-6 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
				<pui-field :label="$t('header.documentid')" :value="getImoFalForm3ID"></pui-field>
			</v-col>
			<v-col class="col-6 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
				<pui-field :label="this.$t('header.visitid')" :value="getVisitid"></pui-field>
			</v-col>
			<v-col class="col-6 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
				<pui-field :label="this.$t('header.shipname')" :value="getShipname"></pui-field>
			</v-col>
			<v-col class="col-6 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
				<pui-field :label="this.$t('header.shiptype')" :value="getShiptype"></pui-field>
			</v-col>
			<v-col class="col-6 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
				<pui-field :label="this.$t('header.imo')" :value="getImo"></pui-field>
			</v-col>
			<v-col class="col-6 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
				<pui-field :label="this.$t('header.callsign')" :value="getCallsign"></pui-field>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col class="col-6 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
				<pui-field :label="this.$t('header.flagdesc')" :value="getFlagdesc"></pui-field>
			</v-col>
			<v-col class="col-6 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
				<pui-field :label="this.$t('header.voyagenum')" :value="getVoyagenum"></pui-field>
			</v-col>
			<v-col class="col-6 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
				<pui-field :label="this.$t('header.portofcall')" :value="getPortofcall"></pui-field>
			</v-col>
			<v-col class="col-6 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
				<pui-field :label="this.$t('header.operation')" :value="getOperation"></pui-field>
			</v-col>
			<v-col class="col-6 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
				<pui-field :label="this.$t('header.status')" :value="getStatus"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'imofalformsform-header',
	data() {
		return {
			pathModelPk: null,
			modelDependency: null,
			parentValue: null
		};
	},
	props: {
		value: {
			type: Object,
			required: false
		},
		modelPk: {
			type: Object
		},
		parentModelName: {
			type: String,
			required: false
		}
	},
	methods: {
		getPkFromUrl() {
			let info = null;
			try {
				info = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			} catch (e) {
				// do nothing
			}

			if (info) {
				this.pathModelPk = info.pk;
			} else if (this.modelPk) {
				this.pathModelPk = this.modelPk;
			}
		},
		loadInfo() {
			if (this.pathModelPk && this.parentModelName) {
				const filter = this.createFilter(this.pathModelPk);
				this.getData(filter);
			}
		},
		createFilter(pk) {
			return this.getParams(pk);
		},
		getParams(pk) {
			return (
				'?' +
				Object.keys(pk)
					.map(function (prop) {
						return [prop, pk[prop]].map(encodeURIComponent).join('=');
					})
					.join('&')
			);
		},
		getData(data) {
			if (!this.modelDependency) {
				return;
			}
			var controller = this.modelDependency.url.get + data;

			this.$puiRequests.getRequest(
				controller,
				null,
				(response) => {
					if (response && response.data && response.data) {
						this.parentValue = response.data;
					}
				},
				(err) => {
					if (err) {
						this.$puiNotify.error(this.$t('form.errorheader'));
					}
				}
			);
		}
	},
	mounted() {
		if (this.parentModelName !== null) {
			this.modelDependency = this.$store.getters.getModelByName(this.parentModelName);
			this.getPkFromUrl();
			this.loadInfo();
		}
	},
	computed: {
		getVisitid() {
			if (this.parentValue !== null) {
				return this.parentValue.portcall.visitid ? this.parentValue.portcall.visitid : '';
			} else {
				return this.value && this.value.portcall.visitid ? this.value.portcall.visitid : '';
			}
		},
		getShipname() {
			if (this.parentValue !== null) {
				return this.parentValue.portcall.mship.shipname ? this.parentValue.portcall.mship.shipname : '';
			} else {
				return this.value && this.value.portcall.mship.shipname ? this.value.portcall.mship.shipname : '';
			}
		},
		getShiptype() {
			if (this.parentValue !== null) {
				return this.parentValue.portcall.mship.shiptype ? this.parentValue.portcall.mship.shiptype : '';
			} else {
				return this.value && this.value.portcall.mship.shiptype ? this.value.portcall.mship.shiptype : '';
			}
		},
		getImo() {
			if (this.parentValue !== null) {
				return this.parentValue.portcall.mship.imo ? this.parentValue.portcall.mship.imo : '';
			} else {
				return this.value && this.value.portcall.mship.imo ? this.value.portcall.mship.imo : '';
			}
		},
		getCallsign() {
			if (this.parentValue !== null) {
				return this.parentValue.portcall.mship.callsign ? this.parentValue.portcall.mship.callsign : '';
			} else {
				return this.value && this.value.portcall.mship.callsign ? this.value.portcall.mship.callsign : '';
			}
		},
		getFlagdesc() {
			if (this.parentValue !== null) {
				return this.parentValue.portcall.mship.flagdesc ? this.parentValue.portcall.mship.flagdesc : '';
			} else {
				return this.value && this.value.portcall.mship.flagdesc ? this.value.portcall.mship.flagdesc : '';
			}
		},
		getVoyagenum() {
			if (this.parentValue !== null) {
				return this.parentValue.portcall.voyagenum ? this.parentValue.portcall.voyagenum : '';
			} else {
				return this.value && this.value.portcall.voyagenum ? this.value.portcall.voyagenum : '';
			}
		},
		getPortofcall() {
			if (this.parentValue !== null) {
				return this.parentValue.portcall.portofcall ? this.parentValue.portcall.portofcall : '';
			} else {
				return this.value && this.value.portcall.portofcall ? this.value.portcall.portofcall : '';
			}
		},
		getOperation() {
			if (this.parentValue !== null) {
				return this.parentValue.operation ? this.parentValue.operation : '';
			} else {
				return this.value && this.value.operation ? this.value.operation : '';
			}
		},
		getStatus() {
			if (this.parentValue !== null) {
				return this.parentValue.status ? this.parentValue.status : '';
			} else {
				return this.value && this.value.status ? this.value.status : '';
			}
		},
		getDocumentID() {
			if (this.parentValue !== null) {
				return this.parentValue.imofalformid ? this.parentValue.imofalformid : '';
			} else {
				return this.value && this.value.imofalformid ? this.value.imofalformid : '';
			}
		},
		getImoFalForm3ID() {
			return this.value != null && this.value.imofalform3 != null && this.value.imofalform3.imofalform3id != null
				? this.value.imofalform3.imofalform3id
				: '';
		}
	}
};
</script>
