import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/es5/util/colors';
import en from 'vuetify/es5/locale/en';

Vue.use(Vuetify);
document.documentElement.style.setProperty('--main-150', '#3d83ae');
/*menu*/
document.documentElement.style.setProperty('--main-200', '#FF6C71');
document.documentElement.style.setProperty('--primarycolor', '#3d83ae');
document.documentElement.style.setProperty('--inputfocuscolor', '#3d83ae');
document.documentElement.style.setProperty('--font-family', 'Montserrat');
//edf4f8
document.documentElement.style.setProperty('--N-25', '#edf4f8');
document.documentElement.style.setProperty('--N-10', '#FFFFFF');

//B9CBD5
//document.getElementsByClassName('fa-angle-double-left').style.content = '#DC292E'; e56208 #f5f5f5
const opts = {
	theme: {
		light: true,
		themes: {
			light: {
				primary: '#2F6383',
				secondary: '#FF6C71',
				accent: colors.orange.darken1,
				error: colors.red.darken1
			}
		}
	},
	icons: {
		iconfont: 'fa'
	},
	lang: {
		locales: { en },
		current: 'en'
	}
};

export default new Vuetify(opts);
